import {
  all,
  call,
  fork,
  put,
  takeLatest,
  delay,
} from '@redux-saga/core/effects';
import actions from './action';
import {
  getOrdersApi,
  editOrderApi,
  getSingleOrderApi,
} from '../../../api/Order/index';

function* fetchOrders(action) {
  try {
    const { data } = yield call(getOrdersApi, action.payload);
    yield put({
      type: actions.GET_ORDERS_SUC,
      payload: data.orderItems,
      count: data.totalCount
    });
  } catch (error) {
    yield put({
      type: actions.GET_ORDERS_FAIL,
      message: error,
    });
  }
}

function* fetchOrder(action) {
  try {
    let { data } = yield call(getSingleOrderApi, action.payload);

    yield put({
      type: actions.GET_ORDER_SUC,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_ORDER_FAIL,
      message: error,
    });
  }
}

function* callEditOrderStatusReq(action) {
  try {
    let apiResponse = yield call(editOrderApi, action.payload);
    let { status } = apiResponse; //no response 204 from server

    yield put({
      type: actions.EDIT_ORDER_SUC,
      payload: action.payload,
    });
    delay(200);
    // debugger;
    // if(status == 200 || status == 204){
    //   const updatedData = yield call(getOrdersApi);
    //     console.log(updatedData);
    //   yield put({
    //     type: actions.GET_ORDERS_SUC,
    //     payload: updatedData.orderItems,
    //     count: updatedData.totalCount
    //   });
    // }
    

  } catch (error) {
    yield put({
      type: actions.EDIT_ORDER_FAIL,
      error,
    });
  }
}

function* fetchOrdersReq() {
  yield takeLatest(actions.GET_ORDERS_REQ, fetchOrders);
}

function* fetchOrderReq() {
  yield takeLatest(actions.GET_ORDER_REQ, fetchOrder);
}

export function* editOrderStatus() {
  yield takeLatest(actions.EDIT_ORDER_REQ, callEditOrderStatusReq);
}

export default function* () {
  yield all([fork(fetchOrdersReq), fork(editOrderStatus), fork(fetchOrderReq)]);
}
