import { call, delay, put, takeEvery } from '@redux-saga/core/effects';
import ShowMessage from '../../../components/Toast/Toast';
import authAction from '../../authentication/redux/actions';
import actions from './actions';
import { getShop, createShopApi } from '../../../api/shop';
import decode from 'jwt-decode';

function* createShopReq(action) {
  try {
    let apiResponse = yield call(createShopApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.CREATE_SHOP_SUC,
      statusCode: status,
      shopData: data,
    });
    yield delay(300);
    const tenant = decode(data.accessToken);
    localStorage.setItem('access_token', data.accessToken);
    localStorage.setItem('refresh_token', data.refreshToken);
    yield put({
      type: authAction.GET_CURRENT_USER_SUC,
      currentUser: tenant,
    });
    yield ShowMessage(status, 'Your Shop has been added successfully.');
    window.location.assign('/dashboard');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.CREATE_SHOP_FAIL,
        payload: err.reponse.message,
      });
    }
    yield put({
      type: actions.CREATE_SHOP_FAIL,
      payload: err.message,
    });
  }
}

function* callFetchShopReq(action) {
  try {
    let apiResponse = yield call(getShop, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: actions.FETCH_SHOP_SUC,
      statusCode: status,
      shopData: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.FETCH_SHOP_FAIL,
        payload: err.reponse.message,
      });
    }
    yield put({
      type: actions.FETCH_SHOP_FAIL,
      payload: err.message,
    });
    const { data, status } = yield call(createShopApi, action.payload);
    yield delay(200);
    const message = 'Shop created Successfully.';
    yield put({
      type: actions.CREATE_SHOP_SUC,
      shopData: data,
      status,
    });
    localStorage.setItem('access_token', data.accessToken);
    localStorage.setItem('refresh_token', data.refreshToken);

    ShowMessage(status, message);
    yield window.location.reload();
  }
}

export function* createShop() {
  yield takeEvery(actions.CREATE_SHOP_REQ, createShopReq);
}

export function* fetchShop() {
  yield takeEvery(actions.FETCH_SHOP_REQ, callFetchShopReq);
}
