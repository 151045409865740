import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Country, ShopName, CreateShop as ShopCreate } from '../../constant';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import actions from './redux/actions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

toast.configure();
const CreateShop = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const shop = useSelector((state) => state.Shop);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.Auth?.currentUser);
  const history = useHistory();
  if (currentUser?.defaultTenant !== -1) {
    history.push('/dashboard');
  }
  const handleForm = (data, e) => {
    dispatch(actions.createShop(data));
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="edit-profile">
          <Row className="form-row">
            <Col xl="12">
              <Form
                onSubmit={handleSubmit(handleForm)}
                className="card shadow-lg"
              >
                <CardHeader>
                  <h4 className="card-title mb-0">{ShopCreate}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="#javascript">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="#javascript">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label className="form-label">
                          {ShopName}
                          <span className="text-danger ml-1">*</span>
                        </Label>
                        <Input
                          name="name"
                          className="form-control"
                          type="text"
                          innerRef={register({
                            required: 'This field is required',
                          })}
                          placeholder={ShopName}
                        />
                        {errors?.name?.type === 'required' ? (
                          <p className="text-danger m-1">
                            {errors.name.message}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <Label className="form-label">Address Line 1</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="address1"
                          innerRef={register({
                            required: 'This field is required',
                          })}
                          placeholder="Address"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <Label className="form-label">Address Line 2</Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="address2"
                          innerRef={register}
                          placeholder="Address line 2"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <FormGroup>
                        <Label className="form-label">State</Label>
                        <Input
                          type="select"
                          name="state"
                          innerRef={register}
                          className="form-control btn-square"
                        >
                          <option value="Bagmati">Bagmati</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="form-label">{Country}</Label>
                        <Input
                          type="select"
                          name="country"
                          innerRef={register}
                          className="form-control btn-square"
                        >
                          <option value="Nepal">Nepal</option>
                          <option value="India">India</option>
                          <option value="Bhutan">Bhutan</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div className="form-group mb-0">
                        <Label className="form-label">Description</Label>
                        <Input
                          name="description"
                          type="textarea"
                          className="form-control"
                          rows="5"
                          innerRef={register}
                          placeholder="Enter About your description"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="text-right">
                  <LoaderButton
                    className="btn btn-primary"
                    isLoading={shop.loading}
                    title="Create Shop"
                  />
                </CardFooter>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default CreateShop;
