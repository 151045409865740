import {
  call,
  put,
  takeEvery,
  all,
  fork,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import {
  getUserProfile,
  updateProfilePic,
  uploadProfilePic,
  getUserProfilePic,
  uploadCoverPicApi,
  getUserCoverPicApi,
  updateProfileApi,
  updateCoverPicfirstApi,
  updateCoverPiclastApi,
  getUserApi,
} from '../../../api/user';
import { sendFile } from '../../../api/axios';
import actions from './action';
import { message } from 'antd';

function* getUserWorker() {
  try {
    const { data } = yield call(getUserApi);
    yield put({
      type: actions.GET_USERS_SUC,
      payload: data,
    });
  } catch (error) {
    if (error && error.response) {
      const status = error?.response?.data?.error?.statusCode || 502;
      yield message.error(error.response.data.error.message);
    }
  }
}

function* callEditProfileReq(action) {
  try {
    let apiResponse = yield call(updateProfileApi, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: action.EDIT_PROFILE_SUC,
    });
  } catch (err) {}
}

function* callFetchProfileReq(action, id) {
  try {
    let apiResponse = yield call(getUserProfile, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: actions.GET_PROFILE_SUC,
      statusCode: status,
      profiles: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_PROFILE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_PROFILE_FAIL,
      payload: err.message,
    });
  }
}

function* callEditProfilePic(action) {
  let { imgurl, imgid } = action?.payload;
  try {
    let apiResponse = yield call(updateProfilePic, imgurl, imgid);
    let { data, status } = apiResponse;
    yield put({
      type: actions.EDIT_PROFILEPIC_SUC,
      payload: imgurl,
      statusCode: status,
    });
    message.success('Profile Image Edited Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Couldnot update your Profile Image');
  }
}

function* callEditCoverPic(action) {
  try {
    let { imgurl, imgid, bucketData } = action?.payload;
    let apiResponse = yield call(updateCoverPicfirstApi, imgurl);
    let { data } = apiResponse;
    delay(2000);
    if (data) {
      let apiResponse2 = yield call(
        updateCoverPiclastApi,
        data?.id,
        bucketData,
      );
      let { status } = apiResponse2;
      if (status >= 200 && status <= 204) {
        yield put({
          type: actions.EDIT_COVERPIC_SUC,
          payload: data[0]?.url || '',
        });
        message.success('Cover Image Edited Successfully');
      } else {
        message.error('Could not update your Cover Image');
        yield put({
          type: actions.EDIT_COVERPIC_FAIL,
          payload: 'Could not update your Cover Image',
        });
      }
    }
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_COVERPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_COVERPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not update your Cover Image');
  }
}

function* callFetchProfilePic(action) {
  try {
    let apiResponse = yield call(getUserProfilePic, action);
    let { data, status } = apiResponse;
    let image = data[data.length - 1]; // it recieves in array so we are taking last image

    yield put({
      type: actions.FETCH_PROFILEPIC_SUC,
      payload: image?.url,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.FETCH_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.FETCH_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not get your Profile Image');
  }
}

function* callFetchCoverPic(action) {
  try {
    let apiResponse = yield call(getUserCoverPicApi, action);
    let { data, status } = apiResponse;
    let image = data[data.length - 1];
    yield put({
      type: actions.FETCH_COVERPIC_SUC,
      payload: image?.url,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.FETCH_COVERPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.FETCH_COVERPIC_FAIL,
      payload: err.message,
    });
    message.error('Could not get your Profile Image');
  }
}

function* callSendFilePic(action) {
  //it gets {file,type}  as params
  try {
    let apiResponse = yield call(sendFile, action.payload?.file);
    let { data, status } = apiResponse;
    let imgurl = data[0]?.url; //taking img url
    let imgid = data[0]?.id; // taking img id
    let bucketData = data[0]?.data; // taking bucket data
    yield put({
      type: actions.UPLOAD_FILE_PROFILEPIC_SUC,
      payload: { imgurl, imgid, bucketData },
    });
    // delay(5000);
    if (action.payload?.type === actions.UPLOAD) {
      //this will run when we upload pic for the first time
      yield put({
        type: actions.UPLOAD_PROFILEPIC_REQ,
        payload: imgurl,
      });
    }
    if (action?.payload?.type === actions.EDIT) {
      //this will run when we edit profile pic
      yield put({
        type: actions.EDIT_PROFILEPIC_REQ,
        payload: { imgurl, imgid }, //passing imgURl and imgid to callEditProfilePic generator fucntion
      });
    }
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* callSendCoverFile(action) {
  try {
    let apiResponse = yield call(sendFile, action.payload?.file);
    let { data, status } = apiResponse;
    let imgurl = data[0]?.url; //taking img url
    let imgid = data[0]?.id; // taking img id
    let bucketData = data[0]?.data; // taking bucket data

    yield put({
      type: actions.UPLOAD_FILE_COVERPIC_SUC,
      payload: { imgurl, imgid, bucketData },
    });
    delay(3000);
    if (action?.payload?.type === actions.UPLOAD) {
      yield put({
        type: actions.UPLOAD_COVERIMG_REQ,
        payload: imgurl,
      });
    }
    if (action?.payload?.type === actions.EDIT) {
      //this will run when we edit covrer pic
      yield put({
        type: actions.EDIT_COVERPIC_REQ,
        payload: { imgurl, imgid, bucketData }, //passing imgURl and imgid to callEditProfilePic generator fucntion
      });
    }
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_FILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_FILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* callUploadCoverPic(action) {
  try {
    let apiResponse = yield call(uploadCoverPicApi, action.payload);

    yield put({
      type: actions.UPLOAD_COVERIMG_SUC,
      payload: apiResponse?.data?.url,
    });
    message.success('Cover Image Added Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('Cover Image is not uploaded');
  }
}

export function* callUploadProfilePic(action) {
  try {
    let apiResponse = yield call(uploadProfilePic, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.UPLOAD_PROFILEPIC_SUC,
      payload: { data, status },
    });
    message.success('Profile Image Added Successfully');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.UPLOAD_PROFILEPIC_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.UPLOAD_PROFILEPIC_FAIL,
      payload: err.message,
    });
    message.error('File is not uploaded');
  }
}

export function* getUserWatcherReq() {
  yield takeEvery(actions.GET_USERS_REQ, getUserWorker);
}

export function* fetchProfile() {
  yield takeEvery(actions.GET_PROFILE_REQ, callFetchProfileReq);
}

export function* editProfile() {
  yield takeEvery(actions.EDIT_PROFILE_REQ, callEditProfileReq);
}

export function* editProfilePicture() {
  yield takeEvery(actions.EDIT_PROFILEPIC_REQ, callEditProfilePic);
}

export function* editCoverPicture() {
  //editing cover picture
  yield takeEvery(actions.EDIT_COVERPIC_REQ, callEditCoverPic);
}

export function* fetchProfilePic() {
  yield takeEvery(actions.FETCH_PROFILEPIC_REQ, callFetchProfilePic);
}

export function* fetchCoverPic() {
  yield takeEvery(actions.FETCH_COVERPIC_REQ, callFetchCoverPic);
}

export function* uploadProfilePicture() {
  yield takeEvery(actions.UPLOAD_PROFILEPIC_REQ, callUploadProfilePic);
}

export function* uploadFilePic() {
  //profile pictre
  yield takeEvery(actions.UPLOAD_FILE_PROFILEPIC_REQ, callSendFilePic);
}

export function* uploadCoverFilePic() {
  // cover image
  yield takeEvery(actions.UPLOAD_FILE_COVERPIC_REQ, callSendCoverFile);
}

export function* uploadCoverPic() {
  yield takeEvery(actions.UPLOAD_COVERIMG_REQ, callUploadCoverPic);
}

export default function* () {
  return yield all([
    fork(fetchProfile),
    fork(editProfile),
    fork(editProfilePicture),
    fork(editCoverPicture),
    fork(fetchProfilePic),
    fork(fetchCoverPic),
    fork(uploadProfilePicture),
    fork(uploadFilePic),
    fork(uploadCoverFilePic),
    fork(uploadCoverPic),
    fork(getUserWatcherReq),
  ]);
}
