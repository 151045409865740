import React from 'react';
import { HashLoader as Loader } from 'react-spinners';

const HashLoader = () => {
  return (
    <>
      <div className="hashloader-container">
        <Loader color="#4644d9" size={50} />
      </div>
    </>
  );
};

export default HashLoader;
