import actions from './actions';

const initialState = {
  loading: false,
  shopData: null,
  message: null,
  status: null,
};

const ShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_SHOP_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_SHOP_SUC:
      return {
        ...state,
        loading: false,
        shopData: action.shopData,
        message: action.message,
        status: action.status,
      };
    case actions.CREATE_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case actions.FETCH_SHOP_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_SHOP_SUC:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        shopData: action.shopData,
      };
    case actions.FETCH_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ShopReducer;
