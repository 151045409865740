import React, { Fragment } from 'react';
import Loader from './loader';
import Taptop from './tap-top';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import ThemeCustomize from './theme-customizer';
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreateShop from '../pages/shop/CreateShop';
import { ConfigProvider } from 'antd';

const AppLayout = ({ children }) => {
  const currentUser = useSelector((state) => state.Auth?.currentUser);
  return (
    <Fragment>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00b96b',
            borderRadius: 2,
            // Alias Token
            colorBgContainer: '#f6ffed',
          },
        }}
      >
        <Loader />
        {currentUser?.defaultTenant === -1 ? (
          <CreateShop />
        ) : (
          <>
            <Taptop />
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
              <Header />
              <div className="page-body-wrapper sidebar-icon">
                <Sidebar />
                <div className="page-body">{children}</div>
                <Footer />
              </div>
            </div>
            <ThemeCustomize />
            <ToastContainer />
          </>
        )}
      </ConfigProvider>
    </Fragment>
  );
};
export default withRouter(AppLayout);
