import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import {
  NewPassword,
  RetypePassword,
  Done,
  EnterMobileNumber,
  EnterOTP,
  Resend,
  ResetPassword,
  RememberPassword,
  SignIn,
  Send,
} from '../../constant';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  postForgotPasswordApi,
  sendForgotpasswordOtpApi,
  verifyForgotpasswordOtpApi,
} from 'api/user';
import { message } from 'antd';
const ForgetpwdPage = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [resetHit, setResetHit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({});

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const handleSendOtp = async () => {
    if (!formState?.mobile) {
      return message.info('Please enter mobile number');
    }
    try {
      const { data: otpRes } = await sendForgotpasswordOtpApi(
        formState?.mobile,
      );

      if (otpRes?.statusCode === 200) {
        message.success('Otp sent successfully');
        setResetHit(true);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? 'Failed to send otp',
      );
    }
  };

  const handleSubmitResetPassword = async () => {
    if (!formState?.password) {
      return message.info('Please enter password');
    }
    if (!formState?.cpassword) {
      return message.info('Please confirm password');
    }
    if (formState?.password !== formState?.cpassword) {
      return message.info('Password does not match');
    }
    try {
      setLoading(true);
      const { data: verifyRes } = await verifyForgotpasswordOtpApi({
        phone: formState?.mobile,
        otp: formState?.otp,
      });
      if (verifyRes?.resetToken) {
        const { data: resetRes } = await postForgotPasswordApi(
          verifyRes?.resetToken,
          formState?.password,
        );
        if (resetRes?.statusCode === 200) {
          message.success('Password changed successfully');
          props.history.push('/login');
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message ?? 'Failed to reset password',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main">
                <div className="mb-4 text-center">
                  <a className="" href="#javascript">
                    <img
                      className=""
                      height={50}
                      src={require('../../assets/images/logo/logo_main.jpg')}
                      alt="looginpage"
                    />
                  </a>
                </div>
                <Form className="theme-form">
                  <h4>{ResetPassword}</h4>
                  <FormGroup>
                    <Label className="col-form-label">
                      {EnterMobileNumber}
                    </Label>
                    <Row>
                      {/* <Col md="3">
                        <Input
                          className="form-control mb-1"
                          type="text"
                          defaultValue="+ 91"
                        />
                      </Col> */}
                      <Col md="12">
                        <Input
                          name="mobile"
                          onChange={handleFieldChange}
                          className="form-control mb-1"
                          type="text"
                          inputMode="numeric"
                          placeholder="000-000-0000"
                          maxLength={10}
                          onKeyDown={(e) => {
                            // allow backspace
                            if (e.key === 'Backspace') return;
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Col>
                      <Col xs="12">
                        <Button
                          color="primary"
                          className="btn-block m-t-10"
                          onClick={handleSendOtp}
                        >
                          {Send}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>

                  {resetHit && (
                    <>
                      <div className="text-center mt-4 mb-4">
                        <span className="reset-password-link">
                          {"If don't receive OTP?"}  
                          <div
                            className="btn-link text-danger"
                            onClick={handleSendOtp}
                          >
                            {Resend}
                          </div>
                        </span>
                      </div>
                      <FormGroup>
                        <Label className="col-form-label pt-0">
                          {EnterOTP}
                        </Label>
                        <Row>
                          <Col>
                            <Input
                              name="otp"
                              onChange={handleFieldChange}
                              className="form-control text-center opt-text"
                              type="text"
                              inputMode="numeric"
                              placeholder="00112"
                              maxLength="6"
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      <h6 className="mt-4">{'Create Your Password'}</h6>
                      <FormGroup>
                        <Label className="col-form-label">{NewPassword}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? 'text' : 'password'}
                          name="password"
                          onChange={handleFieldChange}
                          required=""
                          placeholder="*********"
                        />
                        <div
                          className="show-hide"
                          onClick={() => HideShowPassword(togglePassword)}
                        >
                          <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">
                          Confirm Password
                        </Label>
                        <Input
                          className="form-control"
                          type="password"
                          name="cpassword"
                          onChange={handleFieldChange}
                          required=""
                          placeholder="*********"
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Button
                          color="primary"
                          className="btn-block"
                          onClick={handleSubmitResetPassword}
                        >
                          {Done}
                        </Button>
                      </FormGroup>
                    </>
                  )}
                  <p className="mt-4 mb-0">
                    {'Already have an password?'}
                    <Link to="/login" className="ml-2" href="#javascript">
                      {SignIn}
                    </Link>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetpwdPage;
