import React from 'react';

const BulkProductUpload = React.lazy(() =>
  import('../pages/product/BulkUpload'),
);
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const Coupondetails = React.lazy(() =>
  import('../pages/coupons/CouponDetails'),
);
const ManageCoupons = React.lazy(() => import('../pages/coupons/ManageCoupon'));
const CreateShop = React.lazy(() => import('../pages/shop/CreateShop'));
const VendorShop = React.lazy(() => import('../pages/shop/VendorShop'));
const ViewProducts = React.lazy(() => import('../pages/product/ViewProducts'));
const ViewBundledProducts = React.lazy(() =>
  import('../pages/product/ViewBundledProducts'),
);

const EditProduct = React.lazy(() => import('../pages/product/EditProduct'));
const UserProfile = React.lazy(() => import('../pages/users/UserProfile'));
const SupportTicket = React.lazy(() =>
  import('../pages/support/SupportTickets'),
);
const Faqs = React.lazy(() => import('../pages/faqs/viewFaqs'));

const OrderHistory = React.lazy(() =>
  import('../pages/ordersHistory/OrderHistory'),
);

const UserAdd = React.lazy(() => import('../pages/users/UserAdd'));
const UserEdit = React.lazy(() => import('../pages/users/UserEdit'));
const UserList = React.lazy(() => import('../pages/users/UserList'));
const Deals = React.lazy(() => import('../pages/deals'));
const bulkDeal = React.lazy(() => import('../pages/deals/bulkDeal'));
const AddProductDeal = React.lazy(() =>
  import('../pages/deals/addProductDeal'),
);

const viewReviews = React.lazy(() => import('../pages/reviews/viewReviews'));
const ProductReview = React.lazy(() =>
  import('../pages/reviews/productReviews'),
);

const ProductDetails = React.lazy(() =>
  import('../pages/product/ProductDetails'),
);

const Orders = React.lazy(() => import('../pages/order'));
const OrderEdit = React.lazy(() => import('../pages/order/edit'));
const Pdf = React.lazy(() => import('../pages/pdf'));

// order lead
const AllLeads = React.lazy(() => import('../pages/order-leads'));
const LeadOrders = React.lazy(() => import('../pages/order-leads/LeadOrders'));
const CreateOrderLead = React.lazy(() =>
  import('../pages/order-leads/create-lead'),
);
const CreateOrderFromLead = React.lazy(() =>
  import('../pages/order-leads/create-order-from-lead'),
);
const EditOrderLead = React.lazy(() =>
  import('../pages/order-leads/edit-lead'),
);

export const routes = [
  { path: '/dashboard', Component: Dashboard, title: 'Dashboard' },
  { path: '/profile', Component: UserProfile },
  { path: '/addproduct', Component: ProductDetails, title: 'Add Product' },
  {
    path: '/add-bulk-product',
    Component: BulkProductUpload,
    title: 'Add Bulk Product',
  },

  { path: '/support', Component: SupportTicket },
  { path: '/coupondetails', Component: Coupondetails },
  { path: '/managecoupons', Component: ManageCoupons },
  { path: '/ordershistory', Component: OrderHistory },
  { path: '/create-shop', Component: CreateShop },
  { path: '/shop', Component: VendorShop },

  { path: '/products', Component: ViewProducts, title: 'All Products' },
  { path: '/bundle-products', Component: ViewBundledProducts },
  { path: '/product/edit/:id', Component: EditProduct },
  {
    title: 'Product Details',
    path: '/product/details/:id',
    Component: ProductDetails,
  },

  { path: '/users/add', Component: UserAdd },
  { path: '/users/edit/:id', Component: UserEdit },
  { path: '/users/list', Component: UserList },
  { path: '/FAQs', Component: Faqs },
  { path: '/product-review/:id', Component: ProductReview },
  { path: '/deals', Component: Deals },
  { path: '/reviews', Component: viewReviews },
  { path: '/deal/:id/addproduct', Component: AddProductDeal },
  { path: '/orders/:id/edit', Component: OrderEdit },
  { path: '/orders', Component: Orders },

  // order leads
  { path: '/leads', Component: AllLeads },
  { path: '/lead-orders', Component: LeadOrders },
  { path: '/create-order-lead', Component: CreateOrderLead },
  { path: '/lead/:id/edit', Component: EditOrderLead },
  { path: '/lead/:id/create-order', Component: CreateOrderFromLead },

  { path: '/pdf', Component: Pdf },
  { path: '/deals', Component: Deals },
  { path: '/bulk-product-deal', Component: bulkDeal },
];
