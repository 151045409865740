import {
  all,
  call,
  fork,
  put,
  takeLatest,
  delay,
} from '@redux-saga/core/effects';
import actions from './action';
import {
  editOrderLeadApi,
  fetchOrderLeadApiById,
  getOrderLeadsApi,
} from 'api/Order/order-leads';

function* fetchOrderLeads(action) {
  try {
    const { data } = yield call(getOrderLeadsApi, action.payload);
    yield put({
      type: actions.GET_ORDERLEADS_SUC,
      payload: data.leads,
      count: data.totalCount,
    });
  } catch (error) {
    yield put({
      type: actions.GET_ORDERLEADS_FAIL,
      message: error,
    });
  }
}

function* fetchOrder(action) {
  try {
    let { data } = yield call(fetchOrderLeadApiById, action.payload);

    yield put({
      type: actions.GET_ORDERLEAD_SUC,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_ORDERLEAD_FAIL,
      message: error,
    });
  }
}

function* callEditOrderStatusReq(action) {
  try {
    let apiResponse = yield call(editOrderLeadApi, action.payload);
    let { status } = apiResponse; //no response 204 from server

    yield put({
      type: actions.EDIT_ORDERLEAD_SUC,
      payload: action.payload,
    });
    delay(200);
    // debugger;
    // if(status == 200 || status == 204){
    //   const updatedData = yield call(getOrdersApi);
    //     console.log(updatedData);
    //   yield put({
    //     type: actions.GET_ORDERLEADS_SUC,
    //     payload: updatedData.orderItems,
    //     count: updatedData.totalCount
    //   });
    // }
  } catch (error) {
    yield put({
      type: actions.EDIT_ORDERLEAD_FAIL,
      error,
    });
  }
}

function* fetchOrdersReq() {
  yield takeLatest(actions.GET_ORDERLEADS_REQ, fetchOrderLeads);
}

function* fetchOrderReq() {
  yield takeLatest(actions.GET_ORDERLEAD_REQ, fetchOrder);
}

export function* editOrderStatus() {
  yield takeLatest(actions.EDIT_ORDERLEAD_REQ, callEditOrderStatusReq);
}

export default function* () {
  yield all([fork(fetchOrdersReq), fork(editOrderStatus), fork(fetchOrderReq)]);
}
