import { all } from 'redux-saga/effects';
import { WatcherEcommerceApp } from '../redux/ecommerce';
import { WatcherChatApp } from '../redux/chap-app';
import { WatcherEmailApp } from '../redux/email';
import { watchTodoList } from '../redux/todo';
import { watchBookmarkList } from '../redux/bookmark';
import { watcherTaskApp } from '../redux/task-app';

import {
  fetchCurrentUser,
  login,
  logout,
  getUserPermissions,
} from '../pages/authentication/redux/saga';
import { createShop, fetchShop } from '../pages/shop/redux/saga';
import { clearAlert, setAlert } from '../components/Alert/redux/saga';
import ProductSaga from '../pages/product/redux/saga';
import DealsSaga from '../pages/deals/redux/saga';
import userProfileSaga from '../pages/users/redux/saga';

import VariantSaga from '../pages/productVariants/redux/saga';
import OrderSaga from '../pages/order/redux/saga';
import OrderLeadSaga from '../pages/order-leads/redux/saga';
// import {
//   getRolesWatcher,
//   addUserWatcher,
//   getUserWatcher,
// } from '../pages/users/redux/saga';

export default function* rootSagas() {
  yield all([
    WatcherEcommerceApp(),
    WatcherChatApp(),
    WatcherEmailApp(),
    watchTodoList(),
    watchBookmarkList(),
    watcherTaskApp(),
    /* Classified Sagas */
    login(),
    getUserPermissions(),
    setAlert(),
    clearAlert(),
    fetchCurrentUser(),
    logout(),
    createShop(),
    fetchShop(),
    // add roled users
    // getRolesWatcher(),
    // addUserWatcher(),
    // getUserWatcher(),
    /*----End of Sagas ---*/

    ProductSaga(),
    VariantSaga(),
    OrderSaga(),
    OrderLeadSaga(),
    userProfileSaga(),
    DealsSaga(),
  ]);
}
