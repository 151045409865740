import React from 'react';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  title,
  isAuthenticated,
  layout: Layout,
  ...rest
}) => {
  if(title) {
    document.title = title;
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isAuthenticated ? (
          Layout ? (
            <Layout>
              <Component {...props} {...rest} />
            </Layout>
          ) : (
            <Component />
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
