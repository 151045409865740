const errors = {
  UserDoesNotExists: 'UserDoesNotExists',
  InvalidCredentials: 'Invalid Credentials',
  UnauthorizedError: 'UnauthorizedError',
};

export const getErrorMessage = (errorName) => {
  switch (errorName) {
    case errors.UnauthorizedError:
      return 'Invalid Credentials';
    case errors.InvalidCredentials:
      return 'Username or password invalid.';
    case errors.UserDoesNotExists:
      return 'Username or password invalid.';
    default:
      return;
  }
};
