import axios from '../axios';
import { getBearerToken } from '../../helper/Helper';

export const getOrdersApi = async (filter) => {
  const {
    date,
    code,
    status,
    page,
    perPage,
    emailSearch,
    searchName,
    id,
    searchPhone,
    storeId,
    source,
    initiaterId,
  } = filter;
  let filterArray = [];
  if (perPage) filterArray.push(`&filter[limit]=${perPage}`);
  if (page) filterArray.push(`filter[skip]=${(page - 1) * perPage}`);
  if (code) {
    let searchQuery = new RegExp(code, 'gi');
    filterArray.push(`filter[where][tracking_code][regexp]=${searchQuery}`);
  }
  if (id) {
    filterArray.push(`filter[where][id]=${id}`);
  }
  if (storeId) {
    filterArray.push(`filter[where][storeId]=${storeId}`);
  }
  if (status) {
    if (status === 'delivered') {
      filterArray.push(
        `filter[where][status]=delivered&filter[include][][payment_type]=himalayanBank&filter[include][][payment_type]=esewa&filter[include][][payment_type]=khalti&filter[include][][payment_type]=fonepay`,
      );
    } else {
      filterArray.push(`filter[where][status]=${status}`);
    }
  }
  if (date && date?.length)
    filterArray.push(
      `filter[where][createdOn][between][0]=${new Date(
        date[0],
      ).toISOString()}&filter[where][createdOn][between][1]=${new Date(
        date[1],
      ).toISOString()}`,
    );

  if (emailSearch || searchPhone || searchName) {
    let searchQuery = new RegExp(emailSearch, 'gi');
    filterArray.push(
      `filter[where][or][0][email][regexp]=${searchQuery}&filter[where][or][1][phone_number][regexp]=${searchQuery}&filter[where][or][2][name][regexp]=${searchQuery}`,
    );
  }
  if (searchName) {
    let searchQuery = new RegExp(searchName, 'gi');
    filterArray.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  filterArray.push(`filter[order]=createdOn%20DESC`);
  // ! order data by Pathao/Lead/Direct
  if(source !== 'All') {
    filterArray.push(`filter[where][source]=${source ? source : 'Direct'}`);
  } else {
    filterArray.push(`filter[where][source][neq]=Direct`);
  }
  filterArray.push(`filter[order]=createdOn%20DESC`);

  if (initiaterId) {
    filterArray.push(`filter[where][initiaterid]=${initiaterId}`);
  }

  let response = await axios.get(`/orders?${filterArray.join('&')}`);

  return response;
};

const checkCode = (data) => {
  switch (data?.status) {
    case 'confirmed':
      return 'OD_CREATED';
    case 'rejected':
      return 'OD_CANCELLED';
    default:
      return 'OD_STATUS';
  }
};

export const editOrderApi = (data) => {
  let object = {
    order_code: checkCode(data),
    status: data?.status,
    remarks: `${data.text ? data?.text : data?.status}`,
  };
  return axios.patch(`/orders/changeStatus/?id=${data?.id}`, object, {
    headers: {
      Authorization: getBearerToken(),
    },
  });
};

export const getSingleOrderApi = (id) =>
  axios.get(`/orders/${id}?filter[include][][relation]=productId`, {
    headers: {
      Authorization: getBearerToken(),
    },
  });

export const fetchOrderApiById = (id) => {
  return axios.get(`/orders/${id}`);
};

export const rejectOrder = (id, data) => {
  return axios.patch(`/orders/reject/${id}`, data);
};

export const PaymentStatusChangeApi = (id, body) => {
  return axios.patch(`/transaction-history/${id}`, body);
};
