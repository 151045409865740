const entities = '[CREATE_SHOP]';

const actions = {
  CREATE_SHOP_REQ: `${entities} CREATE_SHOP_REQ`,
  CREATE_SHOP_SUC: `${entities} CREATE_SHOP_SUC`,
  CREATE_SHOP_FAIL: `${entities} CREATE_SHOP_FAIL`,

  FETCH_SHOP_REQ: `${entities} FETCH_SHOP_REQ`,
  FETCH_SHOP_SUC: `${entities} FETCH_SHOP_SUC`,
  FETCH_SHOP_FAIL: `${entities} FETCH_SHOP_FAIL`,

  createShop: (payload) => ({
    type: actions.CREATE_SHOP_REQ,
    payload,
  }),
  fetchShop: (payload) => ({
    type: actions.FETCH_SHOP_REQ,
    payload,
  }),
};

export default actions;
