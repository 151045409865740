import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
// import loginImage from "../../assets/images/login2.png"
import {
  Password,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
} from '../constant';
import authAction from '../pages/authentication/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertItemWithIcon from '../components/Alert/AlertItemWithIcon';
import APP_CONFIG from '../APP_CONFIG';
import { useForm } from 'react-hook-form';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import { Link } from 'react-router-dom';

const LoginPage = (_props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();

  const loginWithJwt = (data) => {
    dispatch(authAction.login(data));
  };
  if (auth.isLoggedIn) {
    if (auth.currentUser?.defaultTenant === APP_CONFIG.DEFAULT_TENANT) {
      return <Redirect to="/create-shop" />;
    }
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container fluid={true} className="signup">
      <Row className="align-items-center">
        <Col xs="12" lg="6" xl="6" className="signup__bg">
          <div className="flex items-center justify-center w-full  sidebar_login">
            <img src={'/login2.jpg'} alt="image" className="img-fluid w-full" />
          </div>
        </Col>
        <Col xs="12" lg="6" xl="6">
          <div className="login-card">
            <div>
              <div className="login-main login-tab mt-0">
                <Form
                  className="theme-form fade-show"
                  onSubmit={handleSubmit(loginWithJwt)}
                >
                  <h4>Sign In</h4>
                  <p>{'Enter your username & password to login'}</p>
                  {auth?.statusCode ? (
                    <AlertItemWithIcon
                      status={auth?.statusCode}
                      message={auth?.message}
                    />
                  ) : null}
                  <FormGroup>
                    <Label className="col-form-label">Username</Label>
                    <Input
                      className="form-control"
                      name="username"
                      type="text"
                      innerRef={register({
                        required: 'Enter username',
                      })}
                      placeholder="Enter username"
                    />
                    {errors?.username?.type === 'required' ? (
                      <div className="text-danger p-2">
                        This field is required.
                      </div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      name="password"
                      className="form-control"
                      type={togglePassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      innerRef={register({
                        required: 'Enter password.',
                      })}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                    {errors?.password?.type === 'required' ? (
                      <div className="text-danger p-2">
                        This field is required.
                      </div>
                    ) : null}
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>

                    <Link to="/forgot-password" className="link">
                      {ForgotPassword}
                    </Link>
                    <LoaderButton
                      title="Login"
                      color="success"
                      className={`btn-block mt-5`}
                      disabled={auth.loading || auth.fetchingCurrentUser}
                      type="submit"
                      isLoading={auth.loading || auth.fetchingCurrentUser}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(LoginPage);
