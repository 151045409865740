import React, { Fragment, useEffect, useRef, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import { routes } from './route';
import 'antd/dist/antd.css';
// Signin page
import Signin from './auth/signin';
import ForgotPassword from './auth/forgot-password';

// Authentication
import Register from './pages/authentication/register';

// Error page
import Error404 from './pages/errors/error404';

// Maintenance
import authActions from './pages/authentication/redux/actions';
import Loader from './layout/loader';

import ProtectedRoute from './utils/ProtectedRoute';
import PrivateRoute from './utils/PrivateRoute';
import AppLayout from './layout/AppLayout';
import ForgetpwdPage from 'pages/authentication/forgetpwd';

// sentry

import * as Sentry from '@sentry/react';
Sentry.init({
  dsn: 'https://3cd56d73e10ad3dc60fe2a790e61bf89@o4506336348012544.ingest.sentry.io/4506336349519872',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function isAuthenticated() {
  if (
    localStorage.getItem('access_token') &&
    localStorage.getItem('refresh_token')
  ) {
    return true;
  }
  return false;
}

const Root = (props) => {
  const dispatch = useDispatch();
  // const history = useHistory();

  // here
  const auth = useSelector((state) => state.Auth);
  const currentUser = useSelector((state) => state.Auth?.currentUser);
  const checkToken = useRef();
  checkToken.current = () => {
    if (localStorage.getItem('access_token')) {
      return dispatch(authActions.getCurrentUser());
    }
    return;
  };

  useEffect(() => {
    checkToken.current();
  }, []);

  return (
    <Fragment>
      <Suspense fallback={<Loader show={true} />}>
        <Loader show={auth.fetchingCurrentUser} />

        <Switch>
          <ProtectedRoute
            isAuthenticated={isAuthenticated()}
            title="Login"
            path={'/login'}
            component={Signin}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated()}
            path={'/signup'}
            component={Register}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated()}
            path={'/forgot-password'}
            // component={ForgotPassword}
            component={ForgetpwdPage}
          />
          {/* <ProtectedRoute
            isAuthenticated={isAuthenticated()}
            path={'/reset-password'}
            component={ForgotPassword}
          /> */}
          <Route path="/" exact render={() => <Redirect to="/dashboard" />} />
          {routes.map(({ path, Component, title }) => (
            <PrivateRoute
              key={`title-${path}`}
              title={title}
              path={path}
              layout={AppLayout}
              component={Component}
              isAuthenticated={isAuthenticated()}
            />
          ))}
          <Route path={'*'} component={Error404}></Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
