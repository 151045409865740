const action = {
  GET_ORDERS_REQ: 'GET_ORDERS_REQ',
  GET_ORDERS_SUC: 'GET_ORDERS_SUC',
  GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',

  GET_ORDER_REQ: 'GET_ORDER_REQ',
  GET_ORDER_SUC: 'GET_ORDER_SUC',
  GET_ORDER_FAIL: 'GET_ORDER_FAIL',

  FILTER_ORDER: 'FILTER_ORDER',

  EDIT_ORDER_REQ: 'EDIT_ORDER_REQ',
  EDIT_ORDER_SUC: 'EDIT_ORDER_SUC',
  EDIT_ORDER_FAIL: 'EDIT_ORDER_FAIL',

  filterOrder: (status) => ({
    type: action.FILTER_ORDER,
    status: status,
  }),
  getOrder: (payload) => ({
    type: action.GET_ORDERS_REQ,
    payload,
  }),
  getSingleOrder: (payload) => ({
    type: action.GET_ORDER_REQ,
    payload,
  }),
  editOrderStatusReq: (status, id, text) => ({
    type: action.EDIT_ORDER_REQ,
    payload: {
      status,
      id,
      text,
    },
  }),
};

export default action;
