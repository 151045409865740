import utility from '../../../utils/Utility';
import variantActions from './actions';

const initialState = {
  product: {},
  productVariants: [],
  selectedImage: [],
  showModal: false,
  originalVariants: [],
  multiVariantType: false,
  variant: [],
  variants: [],
  inventoryType: [
    {
      inventoryType: '',
      options: [],
    },
  ],
};

// const getVariantName = (variant) => {
//   let name = [];
//   for (let attr of variant) {
//     name.push(`${attr.name}`);
//   }
//   let updatedname = name.join('-');
//   return updatedname;
// };

const variantReducer = (state = initialState, action) => {
  switch (action.type) {
    case variantActions.SET_ONE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    // case variantActions.SET_ONE_VALUE:
    //   let variant;
    //   let newVariants = []
    //   if (action.Type === "delete") {
    //     variant = state.variant.filter((variant) =>
    //       action.payload.value.findIndex((i) => i.sku === variant.sku) !== -1  //for deleting process
    //     )
    //   }
    //   if (action.Type === "deleteTypes") {
    //     variant = action.payload.value
    //   }

    //   if (action.Type === "add") { //for adding new varaints
    //     action.payload.value.map((variant) => {
    //       if (state.variant.findIndex((i) => i.sku === variant.sku) === -1) {
    //         newVariants.push(variant);
    //       }

    //     })
    //     variant = [...newVariants]
    //   }
    //   if (action.payload.key === "variant") {
    //     return {
    //       ...state,
    //       variant: [...variant]
    //     }
    //   }
    //   else {
    //     return {
    //       ...state,
    //       [action.payload.key]: action.payload.value,
    //     };
    //   }
    case variantActions.TOGGLE_VARIANT:
      return {
        ...state,
        multiVariantType: !state.multiVariantType,
      };
    case variantActions.ADD_CURRENT_PRODUCT:
      let productVariant = action?.payload?.variants || [];
      productVariant.length && productVariant.map((variant) => {
        delete variant?.variant_option;
        delete variant?.isActive;
        delete variant?.properties;
        delete variant?.productId;
        delete variant?.variantTypes
        variant.color = ""
        variant.name = ""
        variant.images = []
        // variant.price = variant?.price ? +variant.price : state.product.retailPrice
        // variant.salePrice = variant?.salePrice ? +variant.salePrice : state.product.salePrice
      })
      let inventory = action?.payload?.inventoryTypes || []
      inventory.length && inventory.map((type) => (
        type.inventoryTypes,
        type.options
      )
      )
      return {
        ...state,
        product: action.payload,
        variants: productVariant,
        variant: productVariant, // for adding old default variants in new array 
        originalVariants: productVariant,
        generatedVariants: productVariant,
        multiVariantType: productVariant.length ? true : state.toggleVariant,
        inventoryType: inventory.length ? inventory : state.inventoryType
      };

    case variantActions.HANDLE_INPUT_CHANGE:
      state.variant = action.payload.variant;
      return {
        ...state,
      };

    case variantActions.SET_CURRENT_VARIANT:
      return {
        ...state,
        currentVariant: action.payload,
      };
    case variantActions.ADD_SELECTED_IMAGE:
      if (!state.selectedImage.includes(action.payload)) {
        return {
          ...state,
          selectedImage: [...state.selectedImage, action.payload],
        };
      }
      if (state.selectedImage.includes(action.payload)) {
        let filteredImages = state.selectedImage.filter(
          (image) => image !== action.payload,
        );
        return {
          ...state,
          selectedImage: filteredImages,
        };
      }
      return {
        ...state,
      };

    case variantActions.SHOW_MODAL:
      let updateImage =
        state.currentVariant && state.currentVariant.images
          ? state.currentVariant.images
          : [];
      return {
        ...state,
        showModal: true,
        selectedImage: updateImage,
      };
    case variantActions.HIDE_MODAL:
      return {
        ...state,
        selectedImage: [],
        showModal: false,
      };

    case variantActions.CLEAR_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: [],
      };
    case variantActions.SAVE_SELECTED_IMAGE:
      for (let variant of state.variants) {
        if (variant.name === state.currentVariant.name) {
          variant.images = state.selectedImage;
        }
      }
      return {
        ...state,
        selectedImage: [],
        showModal: false,
      };

    case variantActions.CLEAR_PRODUCT:
      return {
        ...state,
        inventoryType: [
          {
            inventoryType: '',
            options: [],
          }
        ],
        product: {},
      }

    default:
      return { ...state };
  }
};

export default variantReducer;
