const apiURL = 'https://mainapi.shreekesh.com/';
// const apiURL = 'http://localhost:5001/';

export const APP_CONFIG = {
  APP_NAME: 'Shreekesh',
  MAIL: 'info@shreekesh.com',
  PHONE: '9810101010',
  COUNTRY: 'NEPAL',
  API_BASE_URL: apiURL,
  FILE_URL: 'https://shreekesh-asset.s3.ap-southeast-1.amazonaws.com',
  AMAZON_URL: 'https://shreekesh-asset.s3.ap-southeast-1.amazonaws.com',
};
