import actions from './action';

const initialState = {
  loading: false,
  data: [],
  message: '',
  error: false,
  singleOrder: null,
  count : 0,
  success:false
};

const OrderLeadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ORDERLEADS_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ORDERLEADS_SUC:
      return {
        ...state,
        data: action.payload,
        loading: false,
        count : action.count
      };
    case actions.GET_ORDERLEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actions.FILTER_ORDER:
      return {
        ...state,
        data: state.orders.filter((order) => order.status === action.status),
      };

    case actions.EDIT_ORDERLEAD_REQ:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case actions.EDIT_ORDERLEAD_SUC:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data.map((order) => {
          if (order.id === action.payload.id) {
            order.status = action.payload.status;
          }
          return order;
        }),
      };
    case actions.GET_ORDERLEAD_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ORDERLEAD_SUC:
      return {
        ...state,
        loading: false,
        singleOrder: action.payload,
      };
    case actions.GET_ORDERLEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default OrderLeadReducer;
