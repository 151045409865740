import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
// import loginImage from "../../assets/images/login2.png"
import {
  Password,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
} from '../constant';
import authAction from '../pages/authentication/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertItemWithIcon from '../components/Alert/AlertItemWithIcon';
import APP_CONFIG from '../APP_CONFIG';
import { useForm } from 'react-hook-form';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import { sendForgotpasswordOtpApi } from 'api/user';

const ForgotPasswordPage = (_props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  if (auth.isLoggedIn) {
    if (auth.currentUser?.defaultTenant === APP_CONFIG.DEFAULT_TENANT) {
      return <Redirect to="/create-shop" />;
    }
    return <Redirect to="/dashboard" />;
  }

  //
  const handleSubmitForm = async (data) => {
    setLoading(true);
    try {
      //
      const { data: resetRes } = await sendForgotpasswordOtpApi(data?.mobile);
      console.log('resetRes? ', resetRes);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid={true} className="signup">
      <Row className="align-items-center justify-content-center">
        <Col xs="12" lg="6" xl="6">
          <div className="login-card">
            <div>
              <div className="login-main login-tab mt-0">
                <Form
                  className="theme-form fade-show"
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <h4>Forgot Password ?</h4>
                  <p>Enter your mobile number to reset your password.</p>
                  {auth?.statusCode ? (
                    <AlertItemWithIcon
                      status={auth?.statusCode}
                      message={auth?.message}
                    />
                  ) : null}
                  <FormGroup>
                    <Label className="col-form-label">Mobile Number</Label>
                    <Input
                      className="form-control"
                      name="mobile"
                      type="text"
                      innerRef={register({
                        required: 'Enter mobile number',
                      })}
                      placeholder="Enter mobile number"
                    />
                    {errors?.mobile?.type === 'required' ? (
                      <div className="text-danger p-2">
                        This field is required.
                      </div>
                    ) : null}
                  </FormGroup>

                  <div className="form-group mb-0">
                    <LoaderButton
                      title="Reset Password"
                      color="success"
                      className={`btn-block mt-5`}
                      disabled={
                        loading || auth.loading || auth.fetchingCurrentUser
                      }
                      type="submit"
                      isLoading={
                        loading || auth.loading || auth.fetchingCurrentUser
                      }
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ForgotPasswordPage);
