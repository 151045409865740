import axios from '../axios';

export const deleteProductDeal = (id) => {
  return axios.delete(`/deals/removeProduct/${id}`);
};

export const getDealsApi = ()=>{
  return axios.get('/deals?filter[where][isActive]=true')
}

export const productSearch = (payload) => {
  let { page, perpage, search, discount } = payload;
  let query = [];


  if (search)
    query.push(
      `filter[where][or][0][name][regexp]=/${search}/gi&filter[where][or][1][keywords][regexp]=/${search}/gi`,
    );
  if (discount) query.push(`filter[where][discountAmount][gt]=0`);
  if (page) query.push(`filter[limit]=${perpage}`);
  if (perpage) query.push(`filter[skip]=${(page - 1) * perpage}`);

  return axios.get(`/products?${query.join('&')}`);
};
