import Todoapp from './todo/reducer';
import Ecommerce from './ecommerce/product/reducer';
import Filters from './ecommerce/filter/reducer';
import Wishlist from './ecommerce/wishlist/reducer';
import Cart from './ecommerce/cart/reducer';
import ChatApp from './chap-app/reducer';
import EmailApp from './email/reducer';
import Customizer from './customizer/reducer';
import Bookmarkapp from './bookmark/reducer';
import Taskapp from './task-app/reducer';
import Projectapp from './project-app/reducer';

// custom reducers

import AuthReducer from '../pages/authentication/redux/reducer';
import Alert from '../components/Alert/redux/reducer';
import ShopReducer from '../pages/shop/redux/reducer';
import productReducer from '../pages/product/redux/reducer';
import userReducer from '../pages/users/redux/reducer';
import variantReducer from '../pages/productVariants/redux/reducers';
import progressReducer from '../components/ProgressModal/Redux/reducer';
import dealsReducer from '../pages/deals/redux/reducer';
import orderReducer from '../pages/order/redux/reducer';
import OrderLeadReducer from 'pages/order-leads/redux/reducer';

const reducers = {
  Todoapp,
  data: Ecommerce,
  filters: Filters,
  Wishlistdata: Wishlist,
  Cartdata: Cart,
  ChatApp,
  EmailApp,
  Customizer,
  Bookmarkapp,
  Taskapp,
  Projectapp,
  /* Classified Reducers */
  Auth: AuthReducer,
  Alert,
  Shop: ShopReducer,
  Product: productReducer,
  User: userReducer,
  Orders: orderReducer,
  OrderLeads: OrderLeadReducer,
  Variants: variantReducer,
  Progress: progressReducer,
  Deals: dealsReducer,
};

export default reducers;
