import axios from 'axios';
import { APP_CONFIG } from '../app/config';
import { clearToken, getBearerToken } from '../helper/Helper';

// const baseUrl = 'http://api.classified.staging.doransoft.com/';

export const clientKeys = {
  client_id: 'webapp',
  client_secret: 'secret',
};

const axiosInstance = axios.create({
  baseURL: APP_CONFIG.API_BASE_URL,
});

axiosInstance.interceptors.request.use((req) => {
  if (!req.headers['Authorization']) {
    if (getBearerToken()) {
      req.headers['Authorization'] = getBearerToken();
    }
  }
  req.headers['ngrok-skip-browser-warning'] = true;
  return req;
});

axiosInstance.interceptors.response.use(
  undefined,
  function axiosRetryInterceptor(err) {
    if (err.response?.status === 401) {
      clearToken();
      // window.location.assign('/login'); // TODO: uncomment on prod
    }
    if (err.response?.status === 403) {
      return Promise.reject('You are not allowed to perform this action');
    }
    if (err?.response?.data?.error) {
      return Promise.reject(err.response.data.error);
    }

    return Promise.reject(err);
  },
);

export const sendFile = (file) => {
  var formData = new FormData();
  formData.append('file', file);
  return axios.post(
    `${APP_CONFIG.API_BASE_URL}/asset-manager/upload-image`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getBearerToken(),
      },
    },
  );
};

// TODO:: remove api call
export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchProjectApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/project.json`);
};

export default axiosInstance;
