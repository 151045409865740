import {
  Home,
  User,
  Truck,
  Paperclip,
  Package,
  Star,
  MessageCircle,
  Gift,
  Plus,
  List,
} from 'react-feather';
import { FaShippingFast } from 'react-icons/fa';
export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        active: false,
        path: '/dashboard',
      },
      {
        title: 'Profile',
        icon: User,
        type: 'link',
        active: false,
        path: '/profile',
      },
      {
        title: 'Website Orders',
        icon: Truck,
        type: 'link',
        active: false,
        path: '/orders',
      },
      {
        title: 'Offline Orders',
        icon: FaShippingFast,
        type: 'sub',
        active: false,
        path: '/order-leads',
        children: [
          {
            title: 'Offline Orders',
            type: 'link',
            active: false,
            path: '/leads',
            permission: 'ViewLead',
          },
          {
            title: 'Manage Offline Orders',
            type: 'link',
            active: false,
            path: '/lead-orders',
          },
        ],
      },
      {
        title: 'Products',
        icon: Package,
        type: 'sub',
        active: false,
        path: '/products',
        permission: 'ViewProduct',
        children: [
          {
            title: 'All Product',
            type: 'link',
            active: false,
            path: '/products',
          },
          {
            title: 'Bundled Product',
            type: 'link',
            active: false,
            path: '/bundle-products',
          },
          {
            title: 'Add Product',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/addproduct',
          },
          //! @zero: feature ?
          // {
          //   title: 'Add Bulk Products',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/add-bulk-product',
          // },
        ],
      },
      {
        title: 'Deals',
        icon: Gift,
        type: 'sub',
        active: false,
        permission: 'ViewDeal',
        children: [
          {
            title: 'All Deals',
            type: 'link',
            active: false,
            path: '/deals',
          },
          {
            title: 'Add Bulk Products',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/bulk-product-deal',
          },
        ],
      },
      {
        title: 'Coupons',
        icon: Paperclip,
        type: 'sub',
        active: false,
        permission: 'ViewCoupon',
        children: [
          {
            title: 'View Coupons',
            type: 'link',
            active: false,
            path: '/managecoupons',
          },
        ],
      },
      {
        title: 'Reviews',
        icon: Star,
        type: 'link',
        active: false,
        path: '/reviews',
        permission: 'ViewReviews',
      },
      {
        title: 'FAQs',
        icon: MessageCircle,
        type: 'link',
        active: false,
        path: '/FAQs',
        permission: 'ViewFaq',
      },
    ],
  },
];
