import axios from '../axios';

export const getCategoryApi = (options) => {
  return axios.get('/categories', options);
};

export const getCategoryByIdApi = (id) => {
  return axios.get(`/categories/${id}`);
};

export const getDeepLinkedCategoryByIdApi = () => {
  return axios.get(`/frontend/category-list`);
};
