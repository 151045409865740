import { all, call, fork, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import {
  deleteProductVariantApi,
  fetchVariantAttributeApi,
  fetchVariantColorApi,
  editProductVariantApi
} from '../../../api/variants';
import ShowMessage from '../../../components/Toast/Toast';
import variantActions from './actions';
import history from '../../../app/history';
import progressAction from '../../../components/ProgressModal/Redux/actions';

// function* getVariantColorReq() {
//   try {
//     const { data } = yield call(fetchVariantColorApi);
//     yield put({
//       type: variantActions.GET_VARIANT_COLOR_SUC,
//       colors: data,
//     });
//   } catch (error) {
//     yield put({
//       type: variantActions.GET_VARIANT_COLOR_FAIL,
//     });
//     ShowMessage(error.status, `couldn't fetch colors.`);
//   }
// }

// function* getVariantAttributeReq(action) {
//   try {
//     const { data } = yield call(fetchVariantAttributeApi, action.id);
//     yield put({
//       type: variantActions.GET_VARIANT_ATTRIBUTES_SUC,
//       attributes: data,
//     });
//   } catch (error) {
//     yield put({
//       type: variantActions.GET_VARIANT_ATTRIBUTES_FAIL,
//     });
//     ShowMessage(error.status, `couldn't fetch Attributes.`);
//   }
// }

// function* addSelectedColorReq(action) {
//   yield put({
//     type: variantActions.ADD_SELECTED_COLOR,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   })
// }

// function* removeSelectedColorReq(action) {
//   yield put({
//     type: variantActions.REMOVE_SELECTED_COLOR,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   });
// }

// function* addSelectedAttributeReq(action) {
//   yield put({
//     type: variantActions.ADD_SELECTED_ATTRIBUTE,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   });
// }

// function* removeSelectedAttributeReq(action) {
//   yield put({
//     type: variantActions.REMOVE_SELECTED_ATTRIBUTE,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   });
// }

// function* handleSelectedAttributeReq(action) {
//   yield put({
//     type: variantActions.HANDLE_SELECTED_ATTRIBUTE,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   });
// }

// function* handleDeselectedAttributeReq(action) {
//   yield put({
//     type: variantActions.HANDLE_DESELECTED_ATTRIBUTE,
//     payload: action.payload,
//   });
//   yield put({
//     type: variantActions.GENERATE_COMBINATION,
//   });
// }

// function* deleteProductVariantReq(action) {
//   try {
//     const { status } = yield call(deleteProductVariantApi, action.payload);
//     yield put({
//       type: variantActions.DELETE_PRODUCT_VARIANT_SUC,
//       payload: action.payload,
//     });
//     yield ShowMessage(status, 'Deleted Successfully 🎉');
//   } catch (error) {
//     yield put({
//       type: variantActions.DELETE_PRODUCT_VARIANT_FAIL,
//     });
//     ShowMessage(error.status, 'Deletion failed');
//   }
// }

// function* editProductVariantReq(action) {
//   try {
//     yield put({
//       type: progressAction.SET_PROGRESS_PERCENTAGE,
//       percentage: 0,
//       status: 'active'
//     })
//     yield delay(200);
//     yield put({
//       type: progressAction.SET_PROGRESS_PERCENTAGE,
//       percentage: 47,
//       status: 'active'
//     });
//     yield delay(200);
//     yield put({
//       type: progressAction.SET_PROGRESS_PERCENTAGE,
//       percentage: 60,
//       status: 'active'
//     });
//     const { data, status } = yield call(
//       editProductVariantApi,
//       action.id,
//       action.data,
//       action.slug
//     );

//     const message = action.isPublished ? "Product Published Successfully." : "Product Saved successfully."
//     yield put({
//       type: variantActions.EDIT_PRODUCT_VARIANT_SUC,
//       payload: data,
//       status: status,
//       message,
//     });
//     yield put({
//       type: progressAction.SET_PROGRESS_PERCENTAGE,
//       percentage: 100,
//       status: 'active'
//     });
//     yield delay(2000);
//     yield put({
//       type: progressAction.SET_SHOW_MODAL,
//       payload: false,
//     });
//     ShowMessage(status, message)
//     history.push('/products');
//     history.go(0);
//   } catch (error) {
//     const message = 'Update failed.';
//     const status = error?.respose?.data?.error?.statusCode || 511;
//     yield put({
//       type: variantActions.EDIT_PRODUCT_VARIANT_FAIL,
//       status,
//       message,
//     });
//     yield put({
//       type: progressAction.SET_PROGRESS_PERCENTAGE,
//       percentage: 100,
//       status: 'exception'
//     });
//     ShowMessage(status, message);
//   }
// }

// export function* editProductVariant() {
//   yield takeLatest(variantActions.EDIT_PRODUCT_VARIANT_REQ, editProductVariantReq);
// }

// function* deleteProductVariant() {
//   yield takeLatest(variantActions.DELETE_PRODUCT_VARIANT_REQ, deleteProductVariantReq);
// }

// function* handleSelectedAttribute() {
//   yield takeEvery(variantActions.HANDLE_SELECTED_ATTRIBUTE_REQ, handleSelectedAttributeReq);
// }

// function* handleDeselectedAttribute() {
//   yield takeEvery(variantActions.HANDLE_DESELECTED_ATTRIBUTE_REQ, handleDeselectedAttributeReq)
// }

// function* addSelectedColor() {
//   yield takeEvery(variantActions.ADD_SELECTED_COLOR_REQ, addSelectedColorReq);
// }
// function* removeSelectedColor() {
//   yield takeEvery(
//     variantActions.REMOVE_SELECTED_COLOR_REQ,
//     removeSelectedColorReq,
//   );
// }
// function* addSelectedAttribute() {
//   yield takeEvery(
//     variantActions.ADD_SELECTED_ATTRIBUTE_REQ,
//     addSelectedAttributeReq,
//   );
// }

// function* removeSelectedAttribute() {
//   yield takeEvery(
//     variantActions.REMOVE_SELECTED_ATTRIBUTE_REQ,
//     removeSelectedAttributeReq,
//   );
// }

// function* getVariants() {
//   yield takeEvery(variantActions.GET_VARIANT_COLOR_REQ, getVariantColorReq);
// }

// function* getAttributes() {
//   yield takeEvery(
//     variantActions.GET_VARIANT_ATTRIBUTES_REQ,
//     getVariantAttributeReq,
//   );
// }



export default function* () {
  return yield all([
    // fork(getVariants),
    // fork(getAttributes),
    // fork(addSelectedColor),
    // fork(addSelectedAttribute),
    // fork(removeSelectedColor),
    // fork(removeSelectedAttribute),
    // fork(handleSelectedAttribute),
    // fork(handleDeselectedAttribute),
    // fork(deleteProductVariant),
    // fork(editProductVariant),
  ]);
}
