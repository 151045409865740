export const All = [
  {
    id: 1,
    title: 'Endless admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '10',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 2,
    title: 'Universal admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '3',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 3,
    title: 'Poco admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '2',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 4,
    title: 'Universal admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '3',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 5,
    title: 'Endless admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '10',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 6,
    title: 'Poco admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '2',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
];

export const Doing = [
  {
    id: 1,
    title: 'Endless admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '+10 More',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 2,
    title: 'Universal admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '+3 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 3,
    title: 'Poco admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '+2 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 4,
    title: 'Universal admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '+3 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 5,
    title: 'Endless admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '+10 More',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 6,
    title: 'Poco admin Design',
    badge: 'Doing',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '+2 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
];

export const Done = [
  {
    id: 1,
    title: 'Endless admin Desig',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '+10 More',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 2,
    title: 'Universal admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '+3 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 3,
    title: 'Poco admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '+2 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 4,
    title: 'Universal admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '24',
    resolved: '24',
    comment: '40',
    like: '+3 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 5,
    title: 'Endless admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '12',
    resolved: '5',
    comment: '7',
    like: '+10 More',
    progress: '70',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
  {
    id: 6,
    title: 'Poco admin Design',
    badge: 'Done',
    img: 'user/3.jpg',
    sites: 'Themeforest, australia',
    desc:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    issue: '40',
    resolved: '40',
    comment: '20',
    like: '+2 More',
    progress: '100',
    customers_img1: 'user/3.jpg',
    customers_img2: 'user/5.jpg',
    customers_img3: 'user/1.jpg',
  },
];
