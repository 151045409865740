import APP_CONFIG from 'APP_CONFIG';
import { Button, Form, Input, message } from 'antd';
import api from 'api/axios';
import { getBearerToken } from 'helper/Helper';
import React, { useEffect, useState } from 'react';

export default function ChangePasswordForm({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [dataForm] = Form.useForm();
  const onFinish = async (values) => {
    setLoading(true);
    values.code = 'PASSWORD_CHANGED';
    try {
      await api.patch(`/change-password`, values);
      message.success('Password changed successfully');
      onClose();
      dataForm.resetFields();
    } catch (error) {
      message.error(
        error?.response?.data?.error?.message || 'Failed to change password',
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      form={dataForm}
      disabled={loading}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      labelAlign="left"
    >
      <Form.Item
        label="Current Password"
        name="oldPassword"
        rules={[
          {
            required: true,
            message: 'Please input your current password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="password"
        rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        rules={[
          { required: true, message: 'Please re-input your new password!' },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          className="btn btn-primary text-center"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
