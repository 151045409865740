import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import reducers from '../redux/index';
import rootSagas from '../sagas';

const history = createHashHistory();

const routerMiddlewares = routerMiddleware(history);

const sagaMiddleware = createSagaMiddleware();

let store = null;

store = createStore(
  combineReducers({
    ...reducers,
    router: routerMiddlewares,
  }),
  compose(
    applyMiddleware(sagaMiddleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //   window.__REDUX_DEVTOOLS_EXTENSION__(), // FIXME: REMOVE IN PUSH
  ),
);

sagaMiddleware.run(rootSagas);

export default store;
