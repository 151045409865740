import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg';
import {
  FileText,
  LogIn,
  Mail,
  User,
  MessageSquare,
  Bell,
  Minimize,
  Search,
  Lock,
} from 'react-feather';
import { useHistory } from 'react-router-dom';
import Bookmark from '../../layout/bookmark';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
} from 'react-switch-lang';

import {
  Notification,
  DeliveryProcessing,
  OrderComplete,
  TicketsGenerated,
  DeliveryComplete,
  CheckAllNotification,
  ViewAll,
  MessageBox,
  EricaHughes,
  KoriThomas,
  Account,
  Inbox,
  Taskboard,
  LogOut,
  AinChavez,
} from '../../constant';
import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';
import { useDispatch, useSelector } from 'react-redux';
import authAction from '../../pages/authentication/redux/actions';
import { Modal } from 'antd';
import { Label } from 'reactstrap';
import axios from 'axios';
import { APP_CONFIG } from '../../app/config';
import { getBearerToken } from '../../helper/Helper';
import ChangePasswordForm from './ChangePasswordForm';

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('en');
setLanguageCookie();

const Rightbar = () => {
  const history = useHistory();
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [moonlight, setMoonlight] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [chatDropDown, setChatDropDown] = useState(false);
  const [modal, setModal] = useState(false);
  const vendorDetails = useSelector((state) => state?.Auth?.currentUser);

  // auth0 profile
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.Auth.currentUser);
  useEffect(() => {
    if (localStorage.getItem('layout_version') === 'dark-only') {
      setMoonlight(true);
    }
  }, []);

  const handleLogout = () => {
    dispatch(authAction.logout());
  };
  const changePassword = (values) => {
    setModal(true);
  };
  const RedirectToChats = () => {
    history.push(`/app/chat-app`);
  };

  const UserMenuRedirect = (redirect) => {
    history.push(redirect);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive);
      document.querySelector('.search-full').classList.add('open');
    } else {
      setSearchresponsive(!searchresponsive);
      document.querySelector('.search-full').classList.remove('open');
    }
  };

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = 'light';
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light);
      document.body.className = 'dark-only';
      localStorage.setItem('layout_version', 'dark-only');
    }
  };

  return (
    <Fragment>
      <Modal
        title="Change Password"
        open={modal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
      >
        <ChangePasswordForm onClose={() => setModal(false)} />
      </Modal>
      <div className="nav-right d-flex justify-content-between col-12 pull-right right-header p-0">
        <p
          style={{ backgroundColor: '#01b051' }}
          className="px-3 py-1 rounded text-white mt-2"
        >
          {vendorDetails?.storeinfo ? (
            <>
              Office: {vendorDetails?.storeinfo[0]?.store?.name}&nbsp;(
              {vendorDetails?.storeinfo[0]?.store?.code}){' '}
            </>
          ) : null}
        </p>
        <ul className="nav-menus mb-0">
          {/* ! theme menu */}
          {/* <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}
              ></i>
            </div>
          </li> */}

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body">
                <p className="mt-2 font-roboto">
                  {currentUser?.firstName}{' '}
                  <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={() => history.push('/profile')}>
                <User />
                <span>{Account} </span>
              </li>
              <li onClick={changePassword}>
                <Lock />
                <span>Change Password</span>
              </li>
              <li onClick={handleLogout}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
