import { toast, cssTransition } from 'react-toastify';
const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  appendPosition: false,
  collapse: true,
  collapseDuration: 300,
});

const config = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 4000,
  hideProgressBar: true,
  pauseOnHover: true,
  transition: Zoom,
  className: 'text-center',
};

const ShowMessage = (status, message) => {
  switch (true) {
    case 100 <= status && status <= 199:
      return toast.info(message, config);
    case 200 <= status && status <= 299:
      return toast.success(message, config);
    case 400 <= status && status <= 499:
      return toast.warn(message, config);
    case 500 <= status && status <= 599:
      return toast.error(message, config);
    default:
      return null;
  }
};

export default ShowMessage;
