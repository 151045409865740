import { APP_CONFIG } from '../app/config';
import { debounce } from 'lodash';

const utility = {
  capitaliseEachInitial: function (text) {
    if (text && text.length > 0) {
      const small_txt = text.toLowerCase();
      const str = small_txt.split(' ');
      for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
      return str.join(' ');
    }
  },

  debounceSearch: debounce(async (e, setter) => {
    setter(e);
  }, 1000),

  calculateDiscount: function (orderItems) {
    let discount = 0;
    if (orderItems && Array.isArray(orderItems) && orderItems?.length > 0) {
      orderItems.forEach((item) => {
        if (item?.product?.dealPrice < item?.product?.retailPrice) {
          discount += item?.product?.retailPrice - item?.product?.dealPrice;
        }
      });
    }
    return discount;
  },

  getTwoDecimalPlace: function (number) {
    return Math.floor(number * 100) / 100
  },
  

  calculateSubTotal: function (orderItems) {
    let total = 0;
    if (orderItems && Array.isArray(orderItems) && orderItems?.length > 0) {
      orderItems.forEach((item) => {
        total += item?.product?.retailPrice * item?.quantity;
      });
    }
    return total;
  },

  getCobinations: (...args) => {
    let r = [];
    let max = args.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = args[i].length; j < l; j++) {
        let a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  },
  fileReader: function (url) {
    if (url?.includes(APP_CONFIG.AMAZON_URL)) {
      return url;
    }
    if (!url?.includes('asset/upload') && !url?.includes('/product')) {
      return `${APP_CONFIG.FILE_URL}/${url}`;
    }
    if (url?.includes('asset/upload')) {
      return `${APP_CONFIG.API_BASE_URL}/${url}`;
    }

    if (url?.includes('/product')) {
      return `${APP_CONFIG.API_BASE_URL}${url}`;
    }
  },
  getDateFormat: function (year) {
    let yearsFormat = [];
    //api.salesberry.com.np/orders?&filter[limit]=10&filter[skip]=0&filter[where][createdOn][between][0]=2022-09-09T00:00:00.000Z&filter[where][createdOn][between][1]=2022-09-09T23:59:59.999Z&&filter[order]=createdOn%20DESC
    https: year.map((date, i) => {
      for (let j = 0; j < 12; j++) {
        yearsFormat.push({
          from: `${date}-${j + 1}-00T00:00:00.000Z`,
          to: `${date}-${j + 1}-30T23:59:59.000Z`,
        });
      }
    });
    return yearsFormat;
  },
};

export default utility;
