import axios from 'axios';
import Axios from '../axios';

export const getUserApi = () => {
  return Axios.get('/blogs');
};
export const addUserApi = (userDetails) => {
  return Axios.post('/register/roled-user', userDetails);
};

export const getUserProfile = (options) => {
  return Axios.get('/users/me', options);
};

export const getUserProfilePic = (options) => {
  return Axios.get(`/profile-image`, options);
};

export const getUserCoverPicApi = (options) => {
  return Axios.get(`/cover-image`, options);
};

// export const updateUserProfile = (options, id) => {
//   return Axios.put(`/users/${id} `, options);
// };

export const updateProfileApi = (options) => {
  return Axios.patch('/profile/update', options);
};

// export const updateProfilePic = (url,imgID) =>{
//   return Axios.put(`/profile-image/${imgID}`,{url: url});
// } // API NEEDS TO BE DONE

export const updateProfilePic = (url) => {
  return Axios.patch('/profile/update', { avatar: url });
};

export const uploadProfilePic = (image) => {
  return Axios.post(`/profile-image`, { url: image });
};

export const uploadCoverPicApi = (image) => {
  return Axios.post(`/cover-image`, { url: image });
};

// export const updateCoverPicApi =(url) =>{
//   return Axios.patch('/profile/update', {"coverImage":[url]});
// }

export const updateCoverPicfirstApi = (url) => {
  return Axios.post(`/cover-image`, { url: url });
};

export const updateCoverPiclastApi = (imgID, bucketData) => {
  let object = {
    key: bucketData.Key,
    bucket: bucketData.Bucket,
    url: bucketData.Location,
  };
  return Axios.put(`/cover-image/${imgID}`, object);
};

export const deleteUserApi = (id) => {
  return Axios.delete(`/users/${id}`);
};

export const provincesApi = () => {
  return Axios.get(`/provinces`);
};

export const districtsApi = (province) => {
  return Axios.get(`/provinces/${province}/districts`);
};

export const municipalitiesApi = (district) => {
  return Axios.get(`/districts/${district}/municipalities`);
};

export const wardsApi = (municipality) => {
  return Axios.get(`/municipalities/${municipality}/wards`);
};

export const getAddress = (id) => {
  return Axios.get(`/wards/${id}`);
};

export const validateUserApi = ({ email, phone }) => {
  const query = [];
  if (email) query.push(`email=${email}`);
  if (phone) query.push(`phone=${phone}`);

  return Axios.get(`/user/validate?${query.join('&')}`);
};

export const sendForgotpasswordOtpApi = (phone) => {
  return Axios.post('/forgot-password/send-otp', { phone });
};

export const verifyForgotpasswordOtpApi = (data) => {
  return Axios.post('/forgot-password/verify-otp', data);
};

export const postForgotPasswordApi = (token, password) => {
  return Axios.post(
    '/forgot-password/update-password',
    { password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getCurrentUserPermissions = () => {
  return Axios.get('/users/me/permissions');
};
