import React, { Fragment } from 'react';
import HashLoader from '../../components/Loaders/HashLoader';

const Loader = ({ show }) => {
  return (
    <Fragment>
      <div className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
        <div className="loader-logo">
          <HashLoader />
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
