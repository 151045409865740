import { APP_CONFIG } from '../../../app/config';
import actions from './action';

const initialState = {
  loading: false,
  message: '',
  profile: [],
  image: '',
  coverImage: '',
  users:[]
};

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USERS_REQ: 
    return {
      ...state,
      loading : true
    }
    case actions.GET_USERS_SUC: 
    return {
      ...state,
      loading : false,
      users: action.payload
    }
    case actions.GET_USERS_FAIL: 
    return {
      ...state,
      loading : false
    }
    case actions.GET_PROFILE_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROFILE_SUC:
      console.log('axtion.profiles is>>', action.profiles);
      return {
        ...state,
        loading: false,
        profile: [action.profiles],
      };
    case actions.GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.FETCH_PROFILEPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_PROFILEPIC_SUC:
      let profileImg = action?.payload.split("/asset/upload/image/")[1]
      return {
        ...state,
        loading: false,
        image: profileImg,
      };
    case actions.FETCH_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.FETCH_COVERPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_COVERPIC_SUC:
      let url = ""
      if (action.payload.includes(APP_CONFIG.AMAZON_URL)) url = APP_CONFIG.AMAZON_URL;
      if (action.payload.includes("/asset/upload/image/")) url = "/asset/upload/image/";

      const coverImagefetch = action?.payload.split(url)[1]
      return {
        ...state,
        loading: false,
        coverImage: coverImagefetch,
      };
    case actions.FETCH_COVERPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.EDIT_PROFILEPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_PROFILEPIC_SUC:
      let profileImgEdit = action?.payload.split("/asset/upload/image/")[1]
      return {
        ...state,
        isLoading: false,
        image: profileImgEdit,
      };
    case actions.EDIT_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.EDIT_COVERPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_COVERPIC_SUC:
      let coverImgEdit = action?.payload.split(process.env.REACT_APP_AMAZON_URL)[1]
      return {
        ...state,
        isLoading: false,
        image: coverImgEdit,
      };
    case actions.EDIT_COVERPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.UPLOAD_FILE_PROFILEPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_FILE_PROFILEPIC_SUC:
      return {
        ...state,
        loading: false,
        // image: action.payload?.imgurl
        // image: {
        //     // url: action.payload.imgurl,
        //     id: action.payload.imgid,
        //     bucketData: action.payload.bucketData
        // }
      };

    case actions.UPLOAD_FILE_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case actions.UPLOAD_PROFILEPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_PROFILEPIC_SUC:
      return {
        ...state,
        loading: false,
        image: action.payload.url,
        // { ...state.image,url: action.payload.url}
      };

    case actions.UPLOAD_PROFILEPIC_FAIL:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actions.UPLOAD_FILE_COVERPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_FILE_COVERPIC_SUC:
      return {
        ...state,
        loading: false,
        // coverImage: action.payload?.imgurl
        // image: {
        //     // url: action.payload.imgurl,
        //     id: action.payload.imgid,
        //     bucketData: action.payload.bucketData
        // }
      };

    case actions.UPLOAD_FILE_COVERPIC_REQ:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.UPLOAD_COVERIMG_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_COVERIMG_SUC:
      let coverImageUpload = action.payload.split(`/asset/upload/image/`)[1]
      return {
        ...state,
        loading: false,
        coverImage: coverImageUpload,
        // { ...state.image,url: action.payload.url}
      };
    case actions.UPLOAD_COVERIMG_FAIL:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ProfileReducer;
