import Axios from '../axios';
import axios from 'axios';
import { clearToken, getBearerToken } from '../../helper/Helper';
import { APP_CONFIG } from '../../app/config';

export const addProductApi = (options) => {
  return Axios.post('/products', options);
};

export const getProductApi = async (filter) => {
  const {
    productPerPage,
    page,
    brand,
    category,
    isActive,
    lowStock,
    name,
    date,
    noVaraint,
    skip,
    isVerified,
    noImages,
    isPublish,
    noCategory,
    selectCategory,
    remarks,
    prodId,
    storeId,
    isBundle = false,
  } = filter;
  let filterArr = [];
  const currentPage = page || 1;
  const limit = productPerPage || 10;

  if (productPerPage) filterArr.push(`filter[limit]=${limit}`);
  if (!skip && currentPage)
    filterArr.push(`filter[skip]=${(currentPage - 1) * productPerPage}`);
  if (currentPage && skip) filterArr.push(`filter[skip]=${skip}`);
  if (brand && brand !== 'all')
    filterArr.push(`filter[where][brands][name]=${brand}`);
  if (category && category !== 'all')
    filterArr.push(`filter[where][category]=${category}`);
  if (isActive) filterArr.push(`filter[where][isActive]=${isActive}`);
  if (isPublish) filterArr.push(`filter[where][published]=${isPublish}`);
  if (lowStock) filterArr.push(`filter[where][stock][lte]=${lowStock}`);
  if (name) {
    let searchQuery = new RegExp(name, 'gi');
    filterArr.push(
      `filter[where][or][0][name][regexp]=${searchQuery}&filter[where][or][1][remarks][regexp]=${searchQuery}&filter[where][or][2][keywords][regexp]=${searchQuery}`,
    );
  }
  // if (remarks) {
  //   let searchQuery = new RegExp(remarks, 'gi');
  //   filterArr.push(`filter[where][remarks][regexp]=${searchQuery}`);
  // }
  if (prodId) {
    let searchQuery = new RegExp(prodId, 'gi');
    filterArr.push(`filter[where][productId][regexp]=${searchQuery}`);
  }
  if (isVerified && isVerified !== 'all')
    filterArr.push(`filter[where][isVerified]=${isVerified}`);
  if (date)
    filterArr.push(
      `filter[where][createdOn][gte]=${date.startDate}&filter[where][createdOn][lte]=${date.endDate}`,
    );
  if (noCategory) {
    filterArr.push(`filter[where][category_id]=${''}`);
  }
  if (selectCategory !== 'all' && selectCategory !== undefined) {
    filterArr.push(`filter[where][category_id]=${selectCategory}`);
  }
  if (storeId)
    filterArr.push(
      `filter[include][0][relation]=stockId&filter[include][0][scope][where][storeId]=${storeId}`,
    );

  filterArr.push(`filter[where][isBundle]=${Boolean(isBundle)}`);
  if (isBundle) {
    filterArr.push(`filter[include][1][relation]=bundle`);
  }

  let response = await Axios.get(
    `/products?${filterArr.join('&')}&filter[order]=modifiedOn%20DESC`,
  );

  if (noVaraint || noImages) {
    let updatedData;
    if (noVaraint) {
      updatedData =
        response?.data?.products &&
        response?.data?.products?.length &&
        response?.data?.products?.filter((item) => {
          if (item.variants && item.variants.length === 1) {
            return true;
          }
        });
    }
    if (noImages) {
      updatedData =
        response?.data?.products &&
        response?.data?.products?.length &&
        response?.data?.products?.filter((item) => {
          if (item?.images ? !item?.images?.length > 0 : true) {
            return true;
          }
        });
    }

    response.data.products = updatedData;
    return response;
  } else {
    return response;
  }

  // return Axios.get(`/vendor/products`);
};
export const fetchSearchQueryApi = (payload) => {
  return Axios.get(`/product/search/?q=${payload}`);
};

export const getProductCountApi = () => {
  return Axios.get('/products/count');
};

export const editProductApi = (id, data) => {
  return Axios.patch(`/products/${id}`, data);
};
export const deleteProductApi = (id) => {
  return Axios.delete(`/products/${id}`);
};

export const getProductByIdApi = (id) => {
  return Axios.get(`/products/${id}`);
};

export const uploadImagesApi = (id, data) => {
  return Axios.post(`/product/${id}/image-upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const fetchProductImagesApi = (id) => {
  return Axios.get(`/product/${id}/images`);
};

export const deleteProductImageApi = (id) => {
  return Axios.delete(`/product-images/${id}`);
};

export const getVariantsApi = (id) => {
  return Axios.get(`categories/${id}/variants`);
};

export const fetchProductVariantApi = (id) => {
  return Axios.get(`/variants/${id}/category-variants`);
};

export const editProductVerifiedStatusApi = (data) => {
  const { slug, ...rest } = data;

  return Axios.patch(`/products/verify/${slug}`, {
    ...rest,
  });
};

export const editProductPublishedApi = (data, slug) => {
  return Axios.patch(`/products/publish/${slug}`, {
    published: data,
  });
};

export const addProductDealsApi = (id, payload) => {
  const apiUrl = APP_CONFIG.API_BASE_URL + `/deals/${id}/product-deals`;
  const accessToken = getBearerToken();
  return fetch(apiUrl, {
    method: 'POST',
    headers: !accessToken ? headers : authHeaders(accessToken),
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      throw new Error('Server Error: ' + err.toString());
    });
};

const headers = {
  'Content-type': 'application/json',
  Accept: '*/*',
  'access-control-allow-methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
  'Access-Control-Allow-Origin': '*',
};

const authHeaders = (accessToken) => ({
  ...headers,
  Authorization: accessToken,
});
