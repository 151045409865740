const action = {
  GET_ORDERLEADS_REQ: 'GET_ORDERLEADS_REQ',
  GET_ORDERLEADS_SUC: 'GET_ORDERLEADS_SUC',
  GET_ORDERLEADS_FAIL: 'GET_ORDERLEADS_FAIL',

  GET_ORDERLEAD_REQ: 'GET_ORDERLEAD_REQ',
  GET_ORDERLEAD_SUC: 'GET_ORDERLEAD_SUC',
  GET_ORDERLEAD_FAIL: 'GET_ORDERLEAD_FAIL',

  FILTER_ORDER: 'FILTER_ORDER',

  EDIT_ORDERLEAD_REQ: 'EDIT_ORDERLEAD_REQ',
  EDIT_ORDERLEAD_SUC: 'EDIT_ORDERLEAD_SUC',
  EDIT_ORDERLEAD_FAIL: 'EDIT_ORDERLEAD_FAIL',

  filterOrderLead: (status) => ({
    type: action.FILTER_ORDER,
    status: status,
  }),
  getOrderLeads: (payload) => ({
    type: action.GET_ORDERLEADS_REQ,
    payload,
  }),
  getSingleOrderLead: (payload) => ({
    type: action.GET_ORDERLEAD_REQ,
    payload,
  }),
  // editOrderLeadStatusReq: (status, id, text) => ({
  //   type: action.EDIT_ORDERLEAD_REQ,
  //   payload: {
  //     status,
  //     id,
  //     text,
  //   },
  // }),
};

export default action;
