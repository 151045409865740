import Axios, { clientKeys } from '../axios';
import axios from 'axios';
import { APP_CONFIG } from '../../app/config';

export const getToken = (options) => {
  return axios.post(`${APP_CONFIG.API_BASE_URL}/auth/vendor-login`, {
    ...clientKeys,
    ...options,
  });
};

export const getAccessToken = (options) => {
  return Axios.post('/auth/token', {
    clientId: clientKeys.client_id,
    ...options,
  });
};

export const getCurrentUser = (options) => {
  return Axios.get('/users/me', options);
};

export const logoutApi = (options) => {
  return Axios.post('/logout', options);
};
