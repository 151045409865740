import React, { Fragment, useState } from 'react';
import { Sliders } from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../helper/Helper';
const Leftbar = () => {
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const width = useWindowSize();

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      document.querySelector('.page-header').className = 'page-header';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className =
          'page-header close_icon';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper close_icon ';
        document
          .querySelector('.mega-menu-container')
          .classList.remove('d-block');
      } else {
        setSidebartoggle(!toggle);
        document.querySelector('.page-header').className = 'page-header';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper ';
      }
    }
  };

  return (
    <Fragment>
      <div
        className="header-logo-wrapper d-flex justify-content-between"
        id="out_side_click"
      >
        <div className="logo-wrapper">
          <Link to={`/dashboard`}>
            <img
              className="img-fluid for-light"
              src={require('../../assets/images/logo/logo.svg')}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              src={require('../../assets/images/logo/logo_dark.jpg')}
              alt=""
            />
          </Link>
        </div>
        <div
          className="toggle-sidebar bg-red"
          onClick={() => responsive_openCloseSidebar(sidebartoggle)}
          style={
            window.innerWidth <= 991
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <Sliders
            className="status_toggle middle sidebar-toggle"
            id="sidebar-toggle"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Leftbar;
